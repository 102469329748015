import React, { Fragment, PureComponent } from 'react'
import { Layout, Jumbotron, Marks } from '../../../components'
import '../../style.less'

class Pizza extends PureComponent {
  componentDidMount () {
    document.body.scrollTop = 0
  }

  render () {
    return (
      <Fragment>
        <Layout
          title={'Pizza'}
          location={this.props.location}
        >
          <Jumbotron
            heading={'Pizza'}
            image={'pizza.jpeg'}
          />
          <div className='container'>
            <Marks />
            <p>All Our Pizzas Are 12”</p>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Margherita <span className='vegetarian-mark'>V</span></h3>
                  <h4>Tomato Base Topped With Mozzarella<p className='pull-right'>£12.95</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Hawaiian</h3>
                  <h4>Tomato Base Topped With Mozzarella, Gammon Ham &amp; Pineapple<p className='pull-right'>£14.95</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Fully Loaded</h3>
                  <h4>BBQ Base Topped With Mozzarella, Sliced Chicken Breast, Gammon Ham, Spicy Ground Beef, Pepperoni &amp; Red Onion<p className='pull-right'>£16.95</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>BBQ Chicken Melt</h3>
                  <h4>BBQ Base Topped With Mozzarella, Sliced Chicken Breast, Red Onion &amp; Sweet Corn<p className='pull-right'>£14.95</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Pepperoni</h3>
                  <h4>Tomato Base Topped With Mozzarella &amp; Pepperoni<p className='pull-right'>£14.95</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12'>
                <div className='para-h4'>
                  <h3 className='para-h2'>Ultimate Veggie <span className='vegetarian-mark'>V</span></h3>
                  <h4>Tomato Base Topped With Mozzarella, Red Onion, Peppers, Mushrooms, Sweet Corn &amp; Cherry Tomatoes<p className='pull-right'>£14.95</p></h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 col-xs-12 mb-4'>
                <h3 className='para-h2'>Additional Toppings</h3>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Red Onion <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£0.60</div></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Mixed Peppers <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£0.60</div></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Sweetcorn <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£0.60</div></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Mushrooms <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£0.60</div></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Cherry Tomatoes <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£0.60</div></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Jalapenos <span className='vegetarian-mark'>V</span> <span className='gf-mark'>GF</span> <div className='pull-right'>£0.60</div></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Sliced Chicken <span className='gf-mark'>GF</span> <div className='pull-right'>£1.25</div></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Gammon Ham <span className='gf-mark'>GF</span> <div className='pull-right'>£1.25</div></div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12 col-xs-12'>
                    <div>Pepperoni<div className='pull-right'>£1.25</div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Fragment>
    )
  }
}

export default Pizza
